const workToolStatusEs = `	
<div id="content" class="content">
<div id="breadcrumbs">
<p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/">
Estado del trabajo relacionado con las Herramientas </a>
</p>
</div>
<div id="getUrlText">
<div style="cursor:pointer;float:right;" title="Collapse tree" id="collapseTree"><i
    class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();"
    style="float:right;"><i class="glyphicon glyphicon-print"></i></a>
</div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:138/header [begin] -->
<div id="c138" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1">
    <h1 class="csc-firstHeader">
    Estado del trabajo relacionado con las Herramientas</h1>
</div>
<!--  Header: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:138/header [end] -->
<!--  CONTENT ELEMENT, uid:137/html [begin] -->
<div id="c137" class="csc-default">
<!--  Raw HTML content: [begin] -->
<table class="public">
    <tbody>
        <tr>
            <td>Documentos de la Corte Penal Internacional
            </td>
            <td align="right">63,108</td>
        </tr>
        <tr>
            <td>Otras Jurisdicciones Penales Internacionales (Internacionalizadas)         
            </td>
            <td align="right">216,912</td>
        </tr>
        <tr>
            <td>Instrumentos Jurídicos Internacionales         
            </td>
            <td align="right">1,320</td>
        </tr>
        <tr>
            <td>Decisiones y Documentos de las Normas de los Derechos Humanos         
            </td>
            <td align="right">8,361</td>
        </tr>
        <tr>
            <td>Otras Decisiones y Documentos de Derecho Internacional 
            </td>
            <td align="right">10,341</td>
        </tr>
        <tr>
            <td>Jurisdicciones Penales Nacionales        
            </td>
            <td align="right">20,448</td>
        </tr>
        <tr>
            <td>Publicaciones</td>
            <td align="right">4,059</td>
        </tr>
        <tr>
            <td>Comisión de Crímenes de Guerra de las Naciones Unidas         
            </td>
            <td align="right">11,809</td>
        </tr>
        <tr>
            <td>Mandatos Internacionales (Internacionalizados) de Determinación de los Hechos </td>
            <td align="right">2,008</td>
        </tr>
        <tr>
            <td></td>
            <td align="right"><strong>338,366</strong></td>
        </tr>
    </tbody>
</table>
<!--  Raw HTML content: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:137/html [end] -->

<!--  CONTENT ELEMENT, uid:50/text [begin] -->
<div id="c50" class="csc-default">
<!--  Text: [begin] -->
<p class="bodytext">(Cifras al 28 noviembre 2024.)</p>
<p class="bodytext"> 
La Base de Datos de Herramientas Jurídicas de la CPI experimenta un desarrollo permanente por parte del Proyecto de Herramientas Jurídicas de la CPI; se cargan a diario nuevos documentos y datos de búsqueda. El objetivo específico del Proyecto consiste en incluir en la Base de Datos de Herramientas Jurídicas de la Corte todos los documentos jurídicos, tanto internacionales como nacionales, que pudiera necesitar un profesional cuyo trabajo se centra en los crímenes internacionales fundamentales.
    </p>
<p class="bodytext">
Se han registrado los metadatos (por ejemplo, el nombre y la fecha de un documento, así como la fecha en que se emitió y por quién fue emitido) para la práctica totalidad de los documentos de la Base de Datos con arreglo al Manual de
    <a
        href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Legal_Tools_Metadata_Manual_version_1-1.pdf"
        target="_blank">Metadatos</a>
        utilizado por el Proyecto de Herramientas Jurídicas de la CPI.
</p>
<p class="bodytext">
La CPI está comprometida con el desarrollo adicional del contenido y 
las calidades técnicas de las Herramientas Jurídicas. Habida cuenta del trabajo 
intensivo que es necesario para recopilar documentos, registrar los metadatos para 
cada uno de ellos y cargarlos en la Base de Datos de  Herramientas Jurídicas de la CPI,
 y también de los recursos humanos limitados con que cuenta la CPI en su capacidad de corte 
 penal operativa, este trabajo correspondiente a las Herramientas Jurídicas se lleva a cabo
  principalmente por agentes externos a la CPI y sin costos para esta. Los asociados externos 
  se sirven de sus propios recursos humanos y recaudan financiación por su cuenta para realiza
  r esta actividad. Su contribución se basa en acuerdos de cooperación formalizados con
   la CPI. Los Gobiernos de Alemania, Austria, Noruega, Países Bajos y Suiza han contribuido
    a las actividades de asociados externosrelacionadas con las Herramientas Jurídicas. En <a href="status-of-work-on-the-tools/table-of-responsibilities/">este</a> cuadro se refleja la distribución del trabajo entre los asociados externos.</p>
<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:50/text [end] -->
</div>

`;
export default workToolStatusEs;
