const workToolStatusFr = `	
<div id="content" class="content">
<div id="breadcrumbs">
<p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/">
État des travaux sur les Outils 
</a>
</p>
</div>
<div id="getUrlText">
<div style="cursor:pointer;float:right;" title="Collapse tree" id="collapseTree"><i
        class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();"
    style="float:right;"><i class="glyphicon glyphicon-print"></i></a>
</div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:138/header [begin] -->
<div id="c138" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1">
    <h1 class="csc-firstHeader">
    État des travaux sur les Outils 
    </h1>
</div>
<!--  Header: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:138/header [end] -->

<!--  CONTENT ELEMENT, uid:137/html [begin] -->
<div id="c137" class="csc-default">
<!--  Raw HTML content: [begin] -->
<table class="public">
    <tbody>
        <tr>
            <td>Documents de la Cour pénale internationale 
            </td>
            <td align="right">63,108</td>
        </tr>
        <tr>
            <td>Autres juridictions pénales internationales ou hybrides  
            </td>
            <td align="right">216,912</td>
        </tr>
        <tr>
            <td>Instruments juridiques internationaux  
            </td>
            <td align="right">1,320</td>
        </tr>
        <tr>
            <td>Décisions et documents sur le droit relatif aux droits de l’homme  
            </td>
            <td align="right">8,361</td>
        </tr>
        <tr>
            <td>Autres décisions et documents de droit international 
            </td>
            <td align="right">10,341</td>
        </tr>
        <tr>
            <td>Juridictions pénales nationales </td>
            <td align="right">20,448</td>
        </tr>
        <tr>
            <td>Publications
            </td>
            <td align="right">4,059</td>
        </tr>
        <tr>
            <td>Commission des Nations Unies pour les crimes de guerre  
            </td>
            <td align="right">11,809</td>
        </tr>
        <tr>
            <td>Mandats d’enquête internationaux ou hybrides
            </td>
            <td align="right">2,008</td>
        </tr>
        <tr>
            <td></td>
            <td align="right"><strong>338,366</strong></td>
        </tr>
    </tbody>
</table>
<!--  Raw HTML content: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:137/html [end] -->

<!--  CONTENT ELEMENT, uid:50/text [begin] -->
<div id="c50" class="csc-default">
<!--  Text: [begin] -->
<p class="bodytext">(Statistiques au 28 novembre 2024)</p>
<p class="bodytext">
Le projet d’Outils juridiques de la CPI développe en permanence la base de données et téléverse quotidiennement de nouveaux documents et données de recherche. L’objectif explicite est d’inclure dans la base de données chaque document juridique – tant international que national – dont un praticien s'intéressant aux principaux crimes internationaux pourrait avoir besoin. 
    </p>
<p class="bodytext">
Les métadonnées (comme le nom et la date d’un document, sa date de publication et son auteur) ont été enregistrées pour presque tous les documents de la base de données, conformément au
     <a
        href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Legal_Tools_Metadata_Manual_version_1-1.pdf"
        target="_blank">manuel dont</a> se sert le projet d’Outils juridiques de la CPI.
</p>
<p class="bodytext">
La CPI s’est engagée à enrichir constamment le contenu et les qualités techniques des Outils juridiques. La collecte des documents, l’enregistrement de leurs métadonnées et le téléversement des documents dans la base de données des Outils juridiques de la CPI représentent une charge de travail importante.  La CPI étant une juridiction pénale active aux ressources humaines limitées, ce travail est en grande partie réalisé par des intervenants extérieurs, sans aucun coût pour la Cour. Ceux-ci utilisent leurs propres ressources humaines et trouvent eux-mêmes le financement nécessaire pour ces activités. Leur contribution repose sur les accords de coopération formellement conclus avec la CPI. Les gouvernements autrichien, allemand, néerlandais, norvégien et suisse ont ainsi contribué aux travaux sur les Outils juridiques menés par les partenaires extérieurs. Vous pouvez consulter <a href="status-of-work-on-the-tools/table-of-responsibilities/">ici</a> un tableau présentant le partage des tâches entre les partenaires extérieurs.</p>
<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:50/text [end] -->
</div>

`;
export default workToolStatusFr;
